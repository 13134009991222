import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
} from '@fortawesome/free-solid-svg-icons';

import Bio from '../../components/bio';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import theme from '../../utils/themeconstants';
import Share from './share';

const PageBackground = styled.div`
  width: 100%;
`;

const BlogContent = styled.div`
  max-width: 672px;
  text-align: left;
  padding: 100px 1.0875rem 100px;
  margin: 0 auto;
  color: black;
  #mdcontent {
    h1 {
      font-weight: 900;
      margin-bottom: 0.5rem;
    }
    sub {
      margin-top: 12px;
      width: 100%;
      text-align: center;
      line-height: 50%;
    }
    .gatsby-resp-image-wrapper {
      margin-top: ${theme.space.lg * 1.5}px;
    }
    .figcaption {
      font-size: small;
      text-align: center;
      margin-bottom: ${theme.space.lg * 1.5}px;
    }
    blockquote {
      font-style: italic;
      font-size: 20px;
    }
    hr {
      margin: ${theme.space.lg * 2}px 0;
      text-align: center;
      height: 0;
      &:before {
        content: '...';
        display: inline-block;
        font-size: 30px;
        letter-spacing: 12px;
        margin-left: 0.6em;
        position: relative;
        top: -32px;
      }
    }
    a {
      color: ${theme.colors.primary};
      transition: color 0.4s;
      &:hover {
        color: ${theme.colors.red};
      }
    }
  }
`;

const Back = styled.div`
  margin: 0 0 1em 0;
`;

const BlogLink = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  color: ${theme.colors.primary};
  padding-bottom: 1px;
  transition: color 0.4s;
  text-decoration: none;
  border-bottom: 1px solid #000;
  &:hover {
    color: ${theme.colors.red};
  }
`;

const MetaDetail = styled.p`
  margin: 0px 0px 2rem;
  font-size: 0.83255rem;
  font-weight: normal;
  color: black;
`;

const FeatureImage = styled(Img)`
  width: 100%;
  margin-bottom: ${theme.space.lg * 2}px;
`;

const PageLinks = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 2em 0;
`;

const Prev = styled.li`
  width: 100%;
`;

const Next = styled.li`
  width: 100%;
`;

const PrevNextLink = styled(Link)`
  color: ${theme.colors.primary};
  text-decoration: none;
  transition: color 0.4s;
  display: inline-block;
  text-align: center;
  width: 100%;
  &:hover {
    color: ${theme.colors.red};
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;
    const { siteUrl } = this.props.data.site.siteMetadata;

    return (
      <Layout dark blog location={this.props.location} title={siteTitle}>
        <SEO
          title={`${post.frontmatter.title} - Tomorrow Blog`}
          description={post.frontmatter.description || post.excerpt}
          previewImageURL={`${siteUrl}${post.frontmatter.image.publicURL}`}
          url={`${siteUrl}/blog${post.fields.slug}`}
          canonicalLink={post.frontmatter.originalURL}
        />
        <PageBackground>
          <BlogContent>
            <Back>
              <BlogLink to="/blog/">Tomorrow Blog</BlogLink>
            </Back>
            <h1>{post.frontmatter.title}</h1>
            <MetaDetail>
              {post.frontmatter.date}
              {' · '}
              {post.fields.readingTime.text}
            </MetaDetail>
            <FeatureImage fluid={post.frontmatter.image.childImageSharp.fluid} alt={post.title} />
            <div id="mdcontent" dangerouslySetInnerHTML={{ __html: post.html }} />
            <Share url={`${siteUrl}/blog${post.fields.slug}`} title={post.frontmatter.title} />
            <hr
              style={{
                marginBottom: '16px',
              }}
            />
            <Bio
              author={post.frontmatter.author.id}
              position={post.frontmatter.author.bio}
              twitter={post.frontmatter.author.twitter}
              avatar={post.frontmatter.author.avatar.childImageSharp.fluid}
            />
            <PageLinks>
              <Next>
                {next && (
                  <PrevNextLink to={`/blog${next.fields.slug}`} rel="next">
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} /> {next.frontmatter.title}
                  </PrevNextLink>
                )}
              </Next>
              <Prev>
                {previous && (
                  <PrevNextLink to={`/blog${previous.fields.slug}`} rel="prev">
                    {previous.frontmatter.title} <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  </PrevNextLink>
                )}
              </Prev>
            </PageLinks>
          </BlogContent>
        </PageBackground>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author {
          id
          bio
          twitter
          avatar {
            childImageSharp {
              fluid(quality: 80, maxWidth: 80) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        image {
          publicURL
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        originalURL
      }
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import theme from '../../utils/themeconstants';

const brandcolors = {
  twitter: '#29A3EF',
  facebook: '#3b5998',
  linkedin: '#0077b5',
};

const ShareContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${theme.space.lg}px 0;
  .shareitem{
    height: 30px;
    display: flex;
    align-items: center;
    margin-left: ${theme.space.sm}px;
    padding: 0 11px;
    cursor: pointer;
    &:focus {
      outline:0;
    }
  }
`;

const SocialText = styled.span`
  font-size: 14px;
  margin-left: ${theme.space.sm}px;
`;

const Share = ({ url, title }) => (
  <ShareContainer>
    Share:
    <TwitterShareButton
      url={url}
      className="shareitem"
      title={title}
      via="tmrowco"
      style={{ color: brandcolors.twitter }}
    >
      <FontAwesomeIcon icon={faTwitter} size="xs" color={brandcolors.twitter} />
      <SocialText>Twitter</SocialText>
    </TwitterShareButton>
    <FacebookShareButton
      url={url}
      className="shareitem"
      style={{ color: brandcolors.facebook }}
    >
      <FontAwesomeIcon icon={faFacebookF} size="xs" color={brandcolors.facebook} />
      <SocialText>Facebook</SocialText>
    </FacebookShareButton>
    <LinkedinShareButton
      url={url}
      className="shareitem"
      title={title}
      style={{ color: brandcolors.linkedin }}
    >
      <FontAwesomeIcon icon={faLinkedinIn} size="xs" color={brandcolors.linkedin} />
      <SocialText>LinkedIn</SocialText> 
    </LinkedinShareButton>
  </ShareContainer>
);

export default Share;
